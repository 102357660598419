<template>
  <v-flex class="d-flex justify-center" :class="dropActiveClass()">
    <div v-show="$refs.upload" class="drop-active">
      <h3>{{ labelAction }} <material-markdown :src="markdown"></material-markdown></h3>
      <div class="footer-status float-right"></div>
      <div v-for="file in files" :key="`${file.name}-${file.version}`">
        <v-alert type="success" v-if="!file.success && !file.error" dense text>
          {{ file.name }} ({{ file.progress }} %)
        </v-alert>
        <v-alert type="success" v-else-if="file.success" dense text>
          {{ file.name }}
        </v-alert>
        <v-alert type="error" v-else-if="file.error" dense text>
          {{ file.error }}
        </v-alert>
      </div>
      <v-btn color="success" class="mr-4" v-if="zipfile" @click="generateCsv">
        {{ $t("component.zipupload.generate-shotlist-example") }}
      </v-btn>
      <v-btn color="blue" v-if="extractButton" class="ma-2" @click="extractZip()">
        {{ $t("component.zipupload.extract-data") }}
      </v-btn>
      <v-select
        v-if="syncButton && !displayOverride"
        v-model="extractMode"
        :items="extractModeValues"
        menu-props="auto"
        hint="Choose your extraction mode"
        label="Choose your extraction mode"
        chips
      ></v-select>
      <v-btn
        color="green"
        v-if="syncButton && !displayOverride"
        class="ma-2"
        :disabled="!extractMode"
        @click="syncImages()"
      >
        {{ $t("component.zipupload.sync-images") }}
      </v-btn>
      <v-btn
        color="pink"
        v-if="syncButton && displayOverride"
        class="ma-2"
        @click="overrideImageEditing()"
      >
        {{ $t("component.zipupload.override-images") }}
      </v-btn>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
    <file-upload
      class="btn btn-primary dropdown-toggle"
      :post-action="postAction"
      :extensions="extensions"
      :accept="accept"
      :multiple="multiple"
      :thread="thread < 1 ? 1 : thread > 5 ? 5 : thread"
      :headers="headers"
      :data="data"
      :drop="drop"
      :timeout="600 * 1000"
      v-model="files"
      @input-filter="inputFilter"
      @input-file="inputFile"
      ref="upload"
    >
      <v-btn color="blue" fab dark justify-center class="mx-2">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </file-upload>
  </v-flex>
</template>

<script>
/* eslint no-param-reassign: 0 */
/* eslint prefer-destructuring: 0 */
import Vue from 'vue';
import { map } from 'bluebird';
import FileUpload from 'vue-upload-component';
import Mixins from '../../mixins';

const baseURL = process.env.VUE_APP_PIM_BASEURL || 'http://localhost:3128/';

export default {
  name: 'ImportUpload',
  props: {
    displayOverride: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    FileUpload,
  },
  mixins: Mixins,
  data: () => ({
    zipfile: null,
    relationfile: null,
    overlay: false,
    extractMode: null,
    files: [],
    relations: [],
    accept: 'text/plain',
    extensions: 'zip',
    actions: {
      zip: {
        action: `${baseURL}/api/v1/import/zip/[importId]/uploadmedia`,
        i18n: 'component.zipupload.drop-zip-to-upload',
        extension: 'zip',
        regex: /\.(zip)$/i,
        markdown: 'zipstructure.md',
      },
      relations: {
        action: `${baseURL}/api/v1/import/uploadrelations/[importId]`,
        i18n: 'component.zipupload.drop-file-to-import-data',
        extension: 'csv,txt,xls,xlsx',
        regex: /\.(csv|txt|xls|xlsx)$/i,
        markdown: 'zipupload.md',
      },
    },
    labelAction: null,
    postAction: null,
    markdown: null,
    multiple: true,
    drop: true,
    data: {},
    imageKey: '',
    thread: 1,
    baseURL: process.env.VUE_APP_PIM_BASEURL || 'http://localhost:3128/',
  }),
  created() {
    this.$emit('btn:disabled', false);
    this.$store.dispatch('zip/reset');
    this.setAction();
  },
  beforeCreate() {
    this.$store.dispatch('api/attribute/list');
  },
  computed: {
    medias() {
      return this.files.length > 0 && this.$store.getters['zip/files'];
    },
    extractButton() {
      return this.zipfile && this.relationfile;
    },
    syncButton() {
      return this.$store.getters['zip/files'].length > 0;
    },
    importObj: function importObj() {
      return this.$store.getters['import/import'];
    },
    importRows: function importRows() {
      return this.$store.getters['import/rows'];
    },
    // come from the previous data_structured
    importVariantsKey() {
      return this.$store.getters['import/variantsKey'];
    },
    filepath() {
      return this.$store.getters['zip/name'];
    },
    mediasImported() {
      return this.$store.getters['import/mediasImported'];
    },
    headers() {
      return {
        Authorization: `Bearer ${this.$auth.accessToken}`,
      };
    },
    fields() {
      return this.$store.getters['api/attribute/fields'];
    },
    extractModeValues() {
      return ([
        {
          text: 'Ean',
          value: this.fields.FIELD_EAN,
        },
        {
          text: 'Supplier Reference',
          value: this.fields.FIELD_SUPPLIERREFCOLOR,
        },
      ]);
    },
  },
  methods: {
    dropActiveClass() {
      if (this.$refs.upload && this.$refs.upload.dropActive) {
        return 'blue lighten-4 elevation-24';
      }
      return '';
    },
    // eslint-disable-next-line consistent-return
    inputFilter: function inputFilter(newFile, oldFile, prevent) {
      this.overlay = true;
      if (newFile && !oldFile) {
        // Filter non-image file
        // Will not be added to files
        if (!this.regex.test(newFile.name)) {
          this.notificationError('The file format is not good');
          this.overlay = false;
          return prevent();
        }
      }

      if (newFile && oldFile) {
        // Update file
        // Increase the version number
        if (!newFile.version) {
          newFile.version = 0;
        }
        newFile.version += 1;
      }

      if (!newFile && oldFile) {
        this.overlay = false;

        // Refused to remove the file
        return prevent();
      }
    },
    /**
     * Has changed
     * @param  Object|undefined   newFile   Read only
     * @param  Object|undefined   oldFile   Read only
     * @return undefined
     */
    inputFile: function inputFile(newFile, oldFile) {
      // eslint-disable-line consistent-return
      this.$log.debug('common.zipupload.inputfile.started');
      // Automatically activate upload
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (!this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // Get update / response data
        this.overlay = false;
        if (newFile.xhr && (newFile.xhr.status === 201 || newFile.xhr.status === 200)) {
          let response = null;
          try {
            response = JSON.parse(newFile.xhr.response);
          } catch (e) {
            this.$log.error('common.zipupload.inputfile.response.isempty');
          }
          if (!response.filepath) {
            this.$log.error('common.zipupload.inputfile.filepath.isempty');
            return;
          }
          this.$log.debug(`common.zipupload.inputfile.type.${response.type}`);
          this.setAction('relations');
          if (response.type === 'zip') {
            this.zipfile = response.filepath;
            this.$store.commit('zip/set_name', response.filepath);
            this.$emit('btn:disabled', false);
          } else if (response.type === 'sheet') {
            this.relationfile = response.filepath;
            this.$store.commit('zip/set_relations', response.data);
            this.relations = response.data;
            this.$emit('btn:disabled', true);
          } else {
            this.$emit('btn:disabled', false);
            throw new Error('common.zipupload.inputfile.type.invalid');
          }
          return;
        }
        let errorResponse = 'XHR  has failed';
        try {
          errorResponse = JSON.parse(newFile.xhr.response).message;
        } catch (err) {} // eslint-disable-line no-empty
        this.$log.error(errorResponse);
        this.notificationError({ message: errorResponse });
      }
    },
    setAction(action = 'zip') {
      this.postAction = this.actions[action].action.replace(
        '[importId]',
        this.$route.params.importId,
      );
      this.labelAction = this.$t(this.actions[action].i18n);
      this.extensions = this.actions[action].extension;
      this.regex = this.actions[action].regex;
      this.markdown = this.actions[action].markdown;
    },
    resetAll() {
      this.$log.debug('common.zipupload.resetall.started');
      this.files = [];
      this.zipfile = null;
      this.overlay = false;
      this.relationfile = null;
      this.setAction();
      this.$store.commit('import/set_medias_imported', []);
      return this.$store.dispatch('zip/reset');
    },
    async extractZip(isPim = false) {
      this.overlay = true;
      this.$log.debug('common.zipupload.extractzip.started', { filepath: this.zipfile });
      if (!this.zipfile) {
        return this.resetAll();
      }
      try {
        let zipfiles = null;
        const endpoint = 'import/extract';
        const payload = { file: this.zipfile };
        if (typeof isPim === 'boolean' && isPim) {
          payload.brandId = this.brandId;
        } else {
          payload.importId = this.$route.params.importId;
        }
        zipfiles = await this.$store.dispatch(endpoint, payload);
        const keys = {
          supplierRefKey: this.supplierRefKey || this.fields.FIELD_SUPPLIERREFCOLOR,
          eanKey: this.eanKey || this.fields.FIELD_EAN,
          colorKey: this.colorKey || this.fields.FIELD_COLOR,
        };
        await this.$store.dispatch('import/mergeZipFileAndShotlist', {
          zipfiles,
          relations: this.relations,
          rows: this.importRows,
          keys,
        });
        this.setAction('relations');
      } catch (err) {
        this.notificationError({ message: err.message });
      }
      this.overlay = false;
      return Promise.resolve(true);
    },
    syncImages() {
      this.$log.debug('common.zipupload.syncimages.started');
      this.$emit('btn:disabled', false);
      if (this.mediasImported.length === 0) {
        this.notificationError('common.zipupload.syncimages.errors');
        return this.resetAll();
      }
      if (!this.extractMode) {
        this.notificationError('Please choose a extraction mode');
        return Promise.resolve(true);
      }
      this.overlay = true;
      const { FIELD_EAN, FIELD_SUPPLIERREFCOLOR } = this.fields;
      const mediasImported = this.mediasImported.reduce((res, media) => {
        // by default, this is the supplierRefKey
        let referenceRelation = media.reference;
        if (this.extractMode === FIELD_EAN) {
          const indexRow = this.importRows.findIndex(m => m[FIELD_EAN] === media.reference);
          if (indexRow !== -1) {
            referenceRelation = this.importRows[indexRow][FIELD_SUPPLIERREFCOLOR];
          }
        }
        if (typeof res[referenceRelation] === 'undefined') {
          res[referenceRelation] = {};
        }
        if (typeof res[referenceRelation][media.marketplace] === 'undefined') {
          res[referenceRelation][media.marketplace] = [];
        }
        this.$log.debug(
          `common.zipupload.syncimages.on.${media.filename}.${media.marketplace}.added`,
        );
        res[referenceRelation][media.marketplace].push(media);
        return res;
      }, {});

      this.importRows.forEach((row) => {
        const mediaImported = mediasImported[row[FIELD_SUPPLIERREFCOLOR]];
        if (typeof mediaImported !== 'undefined') {
          this.$log.debug(
            `common.zipupload.syncimages.import.${row[FIELD_SUPPLIERREFCOLOR]}.withimage`,
          );
          Object.keys(mediaImported).forEach((marketplace) => {
            this.$log.debug(
              `common.zipupload.syncimages.import.${
                row[FIELD_SUPPLIERREFCOLOR]
              }.marketplace.${marketplace}`,
            );
            const medias = mediaImported[marketplace]
              .sort((a, b) => {
                if (Number(a.order) < Number(b.order)) return -1;
                if (Number(a.order) > Number(b.order)) return 1;
                return 0;
              })
              .map(m => m.original);
            this.imageKey = typeof this.imagesKey[marketplace] !== 'undefined'
              ? this.imagesKey[marketplace]
              : this.imageKeyDefault;
            this.$log.debug(
              `common.zipupload.syncimages.import.${row[FIELD_SUPPLIERREFCOLOR]}.keyimage.${
                this.imageKey
              }`,
            );
            if (typeof row[this.imageKey] === 'string') {
              row[this.imageKey] = row[this.imageKey]
                .split(';')
                .concat(medias)
                // the 1st iteration row[this.imageKey] can be empty
                // and with split method generate a empty imageRow
                .reduce((result, imageRow) => {
                  if (imageRow) result.push(imageRow);
                  return result;
                }, []);
              // the first time all image key are nullified
              // or is undefined
              // @see {@link 62b92e7e3f44948b77a35af9d09d7af96597c0db} r
              // egression code from 2022/07/05
            } else if (typeof row[this.imageKey] === 'undefined' || row[this.imageKey] === null) {
              row[this.imageKey] = [...new Set(medias)];
            }
            // https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
            // ES6 has a native object Set to store unique values.
            // To get an array with unique values you could do now this
            Vue.set(row, this.imageKey, [...new Set(row[this.imageKey])].join(';'));
          });
        } else {
          this.$log.error(
            `common.zipupload.syncimages.import.${row[FIELD_SUPPLIERREFCOLOR]}.noimages`,
          );
        }
      });
      return this.$store
        .dispatch('import/update', {
          id: this.$route.params.importId,
          data: {
            result: this.importRows,
            parameters: {
              count: this.importRows.length,
              page: 1,
            },
          },
        })
        .then(() => this.$emit('list:csv'))
        .then(() => this.resetAll())
        .catch(error => this.notificationError({ message: error.message }))
        .finally(() => {
          this.overlay = false;
        });
    },
    getVariantId(media) {
      if (Object.keys(this.importVariantsKey).length === 0) {
        return false;
      }
      const methodspace = 'common.zipupload.getvariantid';
      const { reference } = media;
      const { ean, supplierReference } = this.importVariantsKey;
      const isEan = String.isNumeric(reference) && reference.length === 13;
      if (isEan && typeof ean[reference] !== 'undefined') {
        return ean[reference];
      }
      if (typeof supplierReference[reference] !== 'undefined') {
        return supplierReference[reference];
      }
      this.$log.debug(`${methodspace}.${media.reference}.not.found`);
      return false;
    },
    overrideImageEditing() {
      const methodspace = 'common.zipupload.overrideimageediting';
      this.$log.log(`${methodspace}.started`, this.medias.length);
      if (Object.keys(this.mediasImported).length === 0) {
        this.notificationError(`${methodspace}.mediasimported.empty`);
        return this.resetAll();
      }
      this.overlay = true;
      // updated from src/components/common/MediaGridView.vue
      // for property color, order etc...
      // see getter/zip/files
      this.mediasImported.forEach((media) => {
        this.$log.debug(`${methodspace}.media`, media);
        if (String.isNumeric(media.id)) {
          media.variantId = media.id;
        } else {
          const variantId = this.getVariantId(media);
          if (variantId) {
            media.variantId = variantId;
          }
        }
      });

      // need to send all media by variantid
      const mediasByVariant = this.mediasImported.reduce((result, media) => {
        if (!media.original || !media.variantId) {
          this.$log.error(`${methodspace}.original.or.varianid.${media.reference}.empty`);
          return result;
        }
        if (typeof result[media.variantId] === 'undefined') {
          result[media.variantId] = [];
        }
        const mediaData = { ...media };
        delete mediaData.id;
        result[media.variantId].push(mediaData);
        return result;
      }, {});
      return map(Object.keys(mediasByVariant), (variantId) => {
        this.$log.debug(`${methodspace}.update`);
        return this.$store
          .dispatch('media/editing', { variantId, medias: mediasByVariant[variantId] })
          .then(response => this.$store.dispatch('media/injectInProduct', response))
          .then(() => this.$emit('update:image', true))
          .catch(error => this.notificationError({ message: error.message }));
      })
        .then(() => this.resetAll())
        .finally(() => {
          this.overlay = false;
        });
    },

    async generateCsv(isPim = false) {
      this.overlay = true;
      this.$log.debug('common.zipupload.started');
      if (!this.filepath) {
        throw new TypeError(`common.zipupload.${this.filepath}.invalid`);
      }
      try {
        let filepath = null;
        const endpoint = 'export/shotlistExample';
        const payload = { filepath: this.filepath };
        if (typeof isPim === 'boolean' && isPim) {
          payload.brandId = this.brandId;
        } else {
          payload.importId = this.$route.params.importId;
        }
        filepath = await this.$store.dispatch(endpoint, payload);
        Vue.downloadUrl(`${this.baseURL}/${filepath}`);
      } catch (err) {
        this.notificationError({ message: err.message });
      }
      this.overlay = false;
    },
  },
};
</script>
