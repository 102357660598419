<template>
  <div>
    <v-data-table
      v-if="brands.length > 0"
      :headers="headers"
      :items="files"
      sort-by="stats.mtime"
      sort-desc
      :item-class="checkError"
      class="elevation-1"
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>All files</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-divider v-if="files.length > 0" vertical></v-divider>
          <v-dialog
            v-if="files.length > 0"
            v-model="dialogDeleteAll"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="red"
                v-bind="attrs"
                v-on="on"
                dark
              >
                Reset
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete all items?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDeleteAll = false">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="resetAllFiles">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.stats.mtime ="{ item }">
        {{ formatDate(item.stats.mtime) }}
      </template>
      <template v-slot:item.brandId="{ item }">
        {{ getBrand(item.brandId) }}
      </template>
      <template v-slot:item.state="{ item }">
        <v-chip :color="getColor(item.state)" dark>
          {{ item.state }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="item.hash"
          small
          @click="readItem(item)"
        >
          mdi-magnify
        </v-icon>
        <v-icon small v-if="constants && item.state === constants.STATE_DONE" @click="goToLink(item.filepath)">mdi-link</v-icon>
        <v-icon small v-else>mdi-link-off</v-icon>
        <v-icon
          small
          v-if="constants && item.state !== constants.STATE_DONE"
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <div>
      <v-dialog
        v-model="dialogRead"
        max-width="520"
        persistent
      >
        <v-card>
          <v-card-text>
            <div class="">{{ editedItem }}</div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              secondary
              @click="closeRead()"
            >Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import { map } from 'bluebird';
import NotificationBaseMixins from '@/mixins/notification-base';

const baseURL = process.env.VUE_APP_PIM_BASEURL || 'http://localhost:3128/';
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  mixins: [NotificationBaseMixins],
  data: () => ({
    dialogDelete: false,
    dialogDeleteAll: false,
    dialogRead: false,
    headers: [
      { text: 'Expor title', value: 'title', sortable: false },
      {
        text: 'File',
        align: 'start',
        sortable: false,
        value: 'basename',
      },
      { text: 'Marketplace', value: 'marketplace', sortable: false },
      { text: 'Brand', value: 'brandId', sortable: false },
      { text: 'Size (MB)', value: 'stats.size', sortable: false },
      { text: 'Modified time', value: 'stats.mtime', sortable: false },
      { text: 'State', value: 'state', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    editedItem: {},
  }),
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    brands() {
      return this.$store.getters['brand/brands'];
    },
    constants() {
      return this.$store.getters['export/constants'];
    },
    files() {
      const hashes = this.value.reduce((r, v) => {
        if (typeof r[v.hash] === 'undefined') {
          r[v.hash] = 0;
        }
        r[v.hash] += 1;
        return r;
      }, {});
      return this.value.map(v => ({
        ...v,
        errors: hashes[v.hash] > 1 ? { hash: 'Duplicate hash' } : undefined,
      }));
    },
  },
  methods: {
    ...mapActions('export', ['removeFile']),
    ...mapActions('config', ['getFromHash']),
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
    closeRead() {
      this.dialogRead = false;
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
    async readItem(item) {
      const configObj = await this.getFromHash(item.hash);
      if (!configObj) {
        this.notificationError({ message: `Config not found for hash ${item.hash}` });
      } else {
        this.dialogRead = true;
        this.editedItem = Object.assign({}, configObj);
      }
    },
    deleteItem(item) {
      try {
        this.editedIndex = this.value.findIndex(v => v.basename === item.basename);
        if (this.editedIndex === -1) {
          throw new TypeError(`file ${item.basename} not found`);
        }
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      } catch (e) {
        this.notificationError({ message: e.message });
      }
    },
    deleteItemConfirm() {
      this.value.splice(this.editedIndex, 1);
      this.removeFile(this.editedItem.basename)
        .then(() => this.closeDelete());
    },
    getColor(state) {
      if (state === this.constants.STATE_ERROR) return 'red';
      if (state === this.constants.STATE_UNKNOWN) return 'pink';
      if (state !== this.constants.STATE_DONE) return 'orange';
      return 'green';
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('fr-FR', {
        weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric',
      });
    },
    getBrand(brandId) {
      const brand = this.brands.find(b => b.id === Number(brandId));
      if (!brand) return null;
      return brand.name;
    },
    goToLink(filepath) {
      if (filepath.indexOf('tmp/storage') !== -1) {
        // eslint-disable-next-line no-param-reassign
        filepath = filepath.replace('tmp/storage', 'storage');
      }
      Vue.downloadUrl(`${baseURL}/${filepath}`);
    },
    checkError(row) {
      const classes = [];
      if (typeof row.errors !== 'undefined' && Object.keys(row.errors).length > 0) {
        classes.push('ta-error');
      }
      if (row.exists) {
        classes.push('ta-alreadyexisted');
      }
      return classes.join(' ');
    },
    async resetAllFiles() {
      try {
        const items = [...this.value];
        await map(items, item => this.removeFile(item.basename), { concurrency: 5 });
        this.value = [];
        this.dialogDeleteAll = false;
        this.$emit('reload');
      } catch (e) {
        this.notificationError({ message: e.message });
      }
    },
  },
};
</script>
