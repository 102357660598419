/* eslint no-param-reassign:0 */
// https://vuex.vuejs.org/en/actions.html
import Vue from 'vue';
import axios from 'axios';
import { workflow } from 'theagent-status';
import { errorHandler } from '@/store/modules/global_helpers';
import { ProductList } from '@/models';

const {
  IN_PROGRESS, DONE, ERROR, RE_TRY, NO_STATUS,
} = workflow;

// milli seconds
const DELAY = 5000;
const timeout = parseInt(process.env.VUE_APP_AXIOS_TIMEOUT || 180000, 10);

// eslint-disable-next-line no-unused-vars
const tempUrl = (directory, image, extension = 'jpg') => Vue.pimUrl(`${directory}/${image}.${extension}?ts=${Date.now()}`);

const BASE_URI = '/api/v1/import';
const BASE_PIM_URI = '/api/v1/pim';

const getVariantsKeys = (products) => {
  if (products.length === 0) {
    return false;
  }
  return products.reduce(
    (result, product) => {
      if (product.variant && product.variant.length > 0) {
        product.variant.forEach((variant) => {
          result.supplierReference[variant.supplierReference] = variant.id;
          if (variant.skus && variant.skus.length > 0) {
            variant.skus.forEach((sku) => {
              result.ean[`${sku.ean}`] = variant.id;
            });
          }
        });
      }
      return result;
    },
    {
      supplierReference: {},
      ean: {},
    },
  );
};

const namespace = 'store.import.actions';
export default {
  list({
    commit, state, getters, dispatch,
  }) {
    Vue.$log.info(`${namespace}.list.started`);
    if (state.state.imports === RE_TRY) {
      return Promise.resolve([]);
    }
    if (state.state.imports === DONE) {
      return Promise.resolve(getters.imports);
    }
    if (state.state.imports === IN_PROGRESS) {
      commit('set_state_imports', RE_TRY);
      return new Promise(resolve => setTimeout(() => resolve(dispatch('list')), DELAY));
    }
    commit('set_state_imports', IN_PROGRESS);
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_PIM_URI}/import`)
        .then((response) => {
          if (typeof response === 'undefined') {
            commit('set_state_imports', ERROR);
            throw new TypeError('/api/v1/pim/import empty');
          }
          commit('set_state_imports', DONE);
          commit('set_imports', response.data.result);
          return resolve(response.data);
        })
        .catch((error) => {
          commit('set_state_imports', ERROR);
          return reject(error);
        });
    });
  },
  products({ commit }, payload) {
    Vue.$log.info(`${namespace}.products.started`);
    return new Promise((resolve, reject) => {
      const endpoint = `${BASE_PIM_URI}/collection/${payload.id}/products`;
      axios
        .get(endpoint, payload)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${endpoint} empty`);
          }
          const { products } = response.data.result;
          const productlist = ProductList.create(products);
          commit('set_products', productlist);
          commit('set_variant_keys', getVariantsKeys(productlist));
          Vue.$log.debug(`${namespace}.length.${response.data.result.products.length}`);
          return resolve({
            ...response.data,
            result: productlist,
          });
        })
        .catch(errorHandler(reject));
    });
  },
  // eslint-disable-next-line no-unused-vars
  new({ commit }, importObj) {
    Vue.$log.info(`${namespace}.new.started`);
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_PIM_URI}/import`, importObj)
        .then((response) => {
          Vue.$log.log('this import is created');
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  // eslint-disable-next-line no-unused-vars
  update({ commit }, importObj) {
    Vue.$log.info(`${namespace}.update.started`);
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_PIM_URI}/import/${importObj.id}`, importObj)
        .then(async (response) => {
          const { result } = response.data.structured_data;
          const productlist = ProductList.create(result);
          commit('set_products', productlist);
          commit('set_variant_keys', getVariantsKeys(productlist));
          commit('set_rows', response.data.data.result);
          Vue.$log.log(`this import ${importObj.id} has been uptaded`);
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  // eslint-disable-next-line no-unused-vars
  get({ commit }, id) {
    Vue.$log.info(`${namespace}.get.started`);
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_PIM_URI}/import/${id}`)
        .then((response) => {
          const importData = { ...response.data };
          commit('set_import', importData);
          return resolve(response.data);
        })
        .catch(errorHandler(reject));
    });
  },
  // eslint-disable-next-line no-unused-vars
  delete({ commit }, id) {
    Vue.$log.info(`${namespace}.delete.started`);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${BASE_PIM_URI}/import/${id}`)
        .then((response) => {
          Vue.$log.log(`this import ${id} has been deleted`);
          return resolve(response);
        })
        .catch(errorHandler(reject));
    });
  },
  reset({ commit }) {
    Vue.$log.info(`${namespace}.reset.started`);
    return new Promise((resolve) => {
      commit('set_state_imports', NO_STATUS);
      commit('set_imports', []);
      commit('set_products', []);
      commit('set_variant_keys', getVariantsKeys);
      commit('set_rows', []);
      return resolve({});
    });
  },
  csv({ commit }, payload) {
    Vue.$log.info(`${namespace}.csv.started`);
    let resetParams = '';
    if (typeof payload.reset !== 'undefined' && payload.reset) {
      resetParams = '?reset=data';
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URI}/csv/${payload.id}${resetParams}`, {
          timeout: 360000,
        })
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`/api/v1/import/csv/:id${resetParams} empty`);
          }
          const importData = { ...response.data };
          delete importData.data;
          delete importData.structured_data;
          commit('set_rows', response.data.data.result);
          commit('set_import', importData);
          Vue.$log.log('csv loaded');
          return resolve(response.data.data);
        })
        .catch(errorHandler(reject));
    });
  },
  convert({ commit }, payload) {
    Vue.$log.info(`${namespace}.convert.started`);
    let resetParams = '';
    if (typeof payload.reset !== 'undefined' && payload.reset) {
      resetParams = '?reset=structured_data';
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URI}/convert/${payload.id}${resetParams}`, {
          timeout: 600000,
        })
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.convert.${payload.id}.response.empty`);
          }
          if (Array.isArray(response.data.errors) && response.data.errors.length > 0) {
            response.data.errors.forEach((error) => {
              Vue.$log.error(`${namespace}.convert.error.${error.identifyName}`, error);
            });
            const exception = new Error('Open your console debug, there are a lot of error here');
            exception.name = response.data.errors[0].name;
            exception.data = response.data.errors.reduce((r, d) => {
              r[d.identifyName] = d.description;
              return r;
            }, {});
            throw exception;
          }
          const importData = { ...response.data };
          delete importData.data;
          delete importData.structured_data;

          const { result } = response.data.structured_data;
          const productlist = ProductList.create(result);
          commit('set_products', productlist);
          commit('set_variant_keys', getVariantsKeys(productlist));
          if (Object.keys(response.data.data).length === 0) {
            Vue.$log.error(`${namespace}.convert.rows.empty`);
          } else {
            commit('set_rows', response.data.data.result);
          }
          commit('set_import', importData);
          Vue.$log.log('convert loaded');
          return resolve({
            ...response.data.structured_data,
            result: productlist,
          });
        })
        .catch(errorHandler(reject));
    });
  },
  generate_id({ commit }, payload) {
    Vue.$log.info(`${namespace}.generateid.started`);
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URI}/generate_id/${payload.id}`, {
          timeout,
        })
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.integrity.${payload.id}.response.empty`);
          }
          if (typeof response.data.errors !== 'undefined' && response.data.errors.length > 0) {
            Vue.$log.error(response.data.errors);
          }
          const importData = { ...response.data };
          delete importData.data;
          delete importData.structured_data;

          const { result } = response.data.structured_data;
          const productlist = ProductList.create(result);
          commit('set_products', productlist);
          commit('set_variant_keys', getVariantsKeys(productlist));
          commit('set_rows', response.data.data.result);
          commit('set_import', importData);
          Vue.$log.log('all ids generated');
          return resolve({
            ...response.data.structured_data,
            result: productlist,
          });
        })
        .catch(errorHandler(reject));
    });
  },
  integrity({ commit }, id) {
    Vue.$log.info(`${namespace}.integrity.started`);
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_PIM_URI}/integrity/import/${id}`, {
          timeout,
        })
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.integrity.${id}.response.empty`);
          }
          if (typeof response.data.errors !== 'undefined' && response.data.errors.length > 0) {
            Vue.$log.error(response.data.errors);
          }
          const importData = { ...response.data };
          delete importData.data;
          delete importData.structured_data;

          const { result } = response.data.structured_data;
          const productlist = ProductList.create(result);
          commit('set_products', productlist);
          commit('set_variant_keys', getVariantsKeys(productlist));
          commit('set_rows', response.data.data.result);
          commit('set_import', importData);
          Vue.$log.log(`this importData ${id} has been checked`);
          return resolve({
            ...response.data.structured_data,
            result: productlist,
          });
        })
        .catch(errorHandler(reject));
    });
  },
  integrityCsv({ commit }, id) {
    Vue.$log.info(`${namespace}.integritycsv.started`);
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_PIM_URI}/integrity/import/${id}/csv`, {
          timeout,
        })
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.integritycsv.${id}.response.empty`);
          }
          const importData = { ...response.data };
          if (importData.status === ERROR) {
            Vue.$log.error(`${namespace}.integritycsv.${id}.something.fatal.occured.on.server`);
            throw new TypeError(`${namespace}.integritycsv.${id}.import.status.error`);
          }
          commit('set_rows', response.data.data.result);

          delete importData.data;
          delete importData.structured_data;
          commit('set_import', importData);
          return resolve(response.data.data);
        })
        .catch(errorHandler(reject));
    });
  },
  integrityCsvByIndex({ commit, getters }, payload) {
    Vue.$log.info(`${namespace}.integritycsvbyindex.${payload.id}.started`);
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_PIM_URI}/integrity/import/${payload.id}/csv/${payload.index}`, {
          timeout,
        })
        .then((response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.integritycsvbyindex.${payload.id}.response.empty`);
          }
          const importData = { ...response.data };
          if (importData.status === ERROR) {
            Vue.$log.error(`${namespace}.integritycsvbyindex.${payload.id}.something.fatal.occured.on.server`);
            throw new TypeError(`${namespace}.integritycsvbyindex.${payload.id}.import.status.error`);
          }
          // this notification is hidden due to some UX reasons
          // eslint-disable-next-line max-len
          // Vue.$log.log(`this importData ${payload.id} has been checked`);
          const result = getters.rows;
          if (typeof response.data.data.parameters.index === 'undefined') {
            throw new TypeError(`${namespace}.integritycsvbyindex.${payload.id}.response.${payload.index}.undefined`);
          }
          // https://vuejsdevelopers.com/2017/03/05/vue-js-reactivity/#vueset
          // the new value one didn't have reactive propertyes
          // except if we change all response.data.data
          Vue.set(result, response.data.data.parameters.index, response.data.data.one);
          // result[response.data.data.parameters.index] = response.data.data.one;
          commit('set_rows', result);

          delete importData.data;
          delete importData.structured_data;
          commit('set_import', importData);
          return resolve({
            result,
            parameters: response.data.data.parameters,
          });
        })
        .catch(errorHandler(reject));
    });
  },
  /**
   * deprecated
   * @param  {[type]} options.commit   [description]
   * @param  {[type]} options.dispatch [description]
   * @param  {[type]} id               [description]
   * @return {[type]}                  [description]
   */
  updateMedia({ commit }, id) {
    Vue.$log.info(`${namespace}.updatemedia.started`);

    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI}/updatemedia/${id}`)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.updatemedia.${id}.response.empty`);
          }
          if (typeof response.data.errors !== 'undefined' && response.data.errors.length > 0) {
            Vue.$log.error(response.data.errors);
          }
          const importData = { ...response.data };
          delete importData.data;
          delete importData.structured_data;

          const { result } = response.data.structured_data;
          const productlist = ProductList.create(result);
          commit('set_products', productlist);
          commit('set_variant_keys', getVariantsKeys(productlist));
          commit('set_rows', response.data.data.result);
          commit('set_import', importData);
          Vue.$log.log('all ids generated');
          return resolve({
            ...response.data.structured_data,
            result: productlist,
          });
        })
        .catch(errorHandler(reject));
    });
  },
  async mediasIsReady({}, id) { // eslint-disable-line no-empty-pattern
    Vue.$log.info(`${namespace}.mediasisready.${id}.started`);
    try {
      const response = await axios.get(`${BASE_PIM_URI}/import/${id}/has.medias.ready`);
      if (typeof response === 'undefined') {
        throw new TypeError(`${namespace}.mediasisready.${id}.failed`);
      }
      const { result } = response.data;
      return !!result;
    } catch (err) {
      return errorHandler()(err);
    }
  },
  extract({ commit }, payload) {
    Vue.$log.info('store.media.actions.extract.started');
    let endpoint = '';
    if (typeof payload.importId !== 'undefined') {
      endpoint = `${BASE_URI}/zip/${payload.importId}/extract`;
    } else if (typeof payload.brandId !== 'undefined') {
      endpoint = `${BASE_URI}/zip/${payload.brandId}/extractpim`;
    } else {
      endpoint = `${BASE_URI}/zip/extractpim`;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(
          endpoint,
          { file: payload.file },
          {
            timeout: 360000,
          },
        )
        .then((response) => {
          Vue.$log.log('media zip has been extracted');
          const entries = response.data.entries || [];
          let data = [];
          if (entries.length > 0) {
            data = entries.filter(e => e.extension).map(entry => ({
              id: entry.name,
              original: tempUrl(response.data.directory, entry.name, entry.extension),
            }));
            Vue.$log.debug('store.media.actions.extract.zip', data);
            commit('zip/set_files', data, { root: true });
          }
          return resolve(data);
        })
        .catch(errorHandler(reject));
    });
  },
  mergeZipFileAndShotlist({ commit }, {
    zipfiles, relations, rows, keys,
  }) {
    const { eanKey, supplierRefKey, colorKey } = keys;
    Vue.$log.log('store.media.actions.mergezipfileandshotlist.started');
    // orderMedias variant is used to sort, to check and reorder
    const orderMedias = {};

    const relationsById = relations.reduce((result, relation) => {
      if (typeof result[relation.filename] === 'undefined') {
        result[relation.filename] = [];
      }
      result[relation.filename].push(relation);
      return result;
    }, {});

    const medias = zipfiles
      .reduce((result, item) => {
        Vue.$log.debug(`store.media.actions.mergezipfileandshotlist.processing.${item.id}`);
        if (typeof relationsById[item.id] === 'undefined') {
          Vue.$log.error(
            `store.media.actions.mergezipfileandshotlist.processed.${item.id}.not.relation`,
          );
          result.push(item);
          return result;
        }

        relationsById[item.id].forEach((relation) => {
          const mediaData = { ...item };
          Vue.$log.debug(
            `store.media.actions.mergezipfileandshotlist.relation.${relation.filename}`,
          );

          let marketplace = 'default';
          if (typeof item.marketplace !== 'undefined' && item.marketplace) {
            marketplace = item.marketplace.toLowerCase();
          }

          const { reference, order } = relation;
          let { marketplace: newMarketplace } = relation;
          mediaData.reference = reference;
          if (typeof orderMedias[mediaData.reference] === 'undefined') {
            orderMedias[mediaData.reference] = {};
          }
          if (typeof newMarketplace === 'undefined') {
            newMarketplace = marketplace;
          } else {
            newMarketplace = newMarketplace ? newMarketplace.toLowerCase() : 'default';
          }

          // set an order default based on order of appearance
          if (typeof orderMedias[mediaData.reference][newMarketplace] === 'undefined') {
            orderMedias[mediaData.reference][newMarketplace] = [];
          }
          if (String.isNumeric(order)) {
            mediaData.order = Number(order);
            orderMedias[mediaData.reference][newMarketplace].push(mediaData.id);
          } else if (
            typeof mediaData.order === 'undefined'
            || !mediaData.order
            || !String.isNumeric(mediaData.order)
          ) {
            const indexMediaOrder = orderMedias[mediaData.reference][newMarketplace].findIndex(
              i => i && i === mediaData.id,
            );
            if (indexMediaOrder === -1) {
              orderMedias[mediaData.reference][newMarketplace].push(mediaData.id);
              mediaData.order = 99;
            }
          }
          mediaData.marketplace = newMarketplace;

          if (rows.length > 0) {
            let indexCol = -1;
            // check if exist inside the catalog imported
            if (String.isEan(mediaData.reference)) {
              indexCol = rows.findIndex(r => r[eanKey] && r[eanKey].trim() === mediaData.reference);
            } else {
              // eslint-disable-next-line max-len
              indexCol = rows.findIndex(r => r[supplierRefKey] && r[supplierRefKey].trim() === mediaData.reference);
            }
            if (indexCol !== -1) {
              // eslint-disable-next-line no-param-reassign
              mediaData.color = rows[indexCol][colorKey];
              mediaData.variantId = typeof rows[indexCol].id !== 'undefined' ? rows[indexCol].id : null;
              mediaData.productId = typeof rows[indexCol].productId !== 'undefined' ? rows[indexCol].productId : null;
            }
          }
          result.push(mediaData);
        });
        return result;
      }, [])
      .sort((a, b) => {
        if (a.reference < b.reference) return -1;
        if (a.reference > b.reference) return 1;
        if (a.marketplace < b.marketplace) return -1;
        if (a.marketplace > b.marketplace) return 1;
        if (Number(a.order) < Number(b.order)) return -1;
        if (Number(a.order) > Number(b.order)) return 1;
        return 0;
      });
    commit('set_medias_imported', medias);
    Vue.$log.debug(`${namespace}.mergezipfileandshotlist.closed`);
    return medias;
  },
  storeInDb({}, payload) { // eslint-disable-line no-empty-pattern
    Vue.$log.info(`${namespace}.storeindb.started`);
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_PIM_URI}/import/store.in.db`, payload, {
          timeout: 360000,
        })
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError(`${namespace}.storeindb.response.failed`);
          }
          const { result } = response.data;
          return resolve(result);
        })
        .catch(errorHandler(reject));
    });
  },
  syncMarketplace({ commit }, id) {
    Vue.$log.info('store.export.actions.started');
    return new Promise((resolve, reject) => {
      axios
        .put(`${BASE_URI}/sync-marketplace/${id}`)
        .then(async (response) => {
          if (typeof response === 'undefined') {
            throw new TypeError('/api/v1/sync-marketplace/:id empty');
          }
          const importObj = { ...response.data };
          delete importObj.data;
          delete importObj.structured_data;
          const productlist = ProductList.create(response.data.structured_data.result);
          commit('set_products', productlist);
          if (Object.keys(response.data.data).length === 0) {
            Vue.$log.error(`${namespace}.syncmarketplace.rows.empty`);
          } else {
            commit('set_rows', response.data.data.result);
          }
          commit('set_import', importObj);
          return resolve({
            ...response.data.structured_data,
            result: productlist,
          });
        })
        .catch(errorHandler(reject));
    });
  },
};
